import { createStore } from 'vuex'

const state = {
  loading: false,
  user: null,
  userType: null,
  facilityID: null,
  voiceType: null,
  facilityData: null,
  hospitalGuid: "",
  connectedPhoneNumber: ""
}

const getters = {
  loading: state => {
    return state.loading;
  },
  user: state => {
    return state.user;
  },
  userType: state => {
    return state.userType;
  },
  facilityID: state => {
    return state.facilityID;
  },
  voiceType: state => {
    return state.voiceType;
  },
  facilityData: state => {
    return state.facilityData
  },
  hospitalGuid: state => {
    return state.hospitalGuid
  },
  connectedPhoneNumber: state => {
    return state.connectedPhoneNumber
  }
}

const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setUser(state, user) {
    state.user = user
  },
  setUserType(state, userType) {
    state.userType = userType
  },
  setFacilityID(state, facilityID) {
    state.facilityID = facilityID
  },
  setVoiceType(state, voiceType) {
    state.voiceType = voiceType
  },
  setFacilityData(state, facilityData) {
    state.facilityData = facilityData
  },
  setHospitalGuid(state, hospitalGuid) {
    state.hospitalGuid = hospitalGuid
  },
  setConnectedPhoneNumber(state, connectedPhoneNumber) {
    state.connectedPhoneNumber = connectedPhoneNumber
  }
}

const actions = {}

const modules = {}

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules
})

export default store